'use client';

import { usePrivy } from '@privy-io/react-auth';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { useAccount, useEnsName } from 'wagmi';
if (typeof window !== 'undefined' && window.location.hostname === process.env.NEXT_PUBLIC_OUTPAINT_HOST!) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: '/ingest',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false //  avoid the initial pageview from being duplicated
  });
}
export function CSPostHogProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="CSPostHogProvider" data-sentry-source-file="posthog-provider.tsx">
      <PostHogAuthWrapper data-sentry-element="PostHogAuthWrapper" data-sentry-source-file="posthog-provider.tsx">{children}</PostHogAuthWrapper>
    </PostHogProvider>;
}
function PostHogAuthWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    address
  } = useAccount();
  const {
    ready,
    user,
    authenticated
  } = usePrivy();
  const {
    data: ensName
  } = useEnsName({
    address
  });

  /** regardless ensName is not ready  */
  /** identify user in support as a visitor or post sign in as a customer to their privyId  */
  /** can call atlas.call multiple times post login when user adds email at a later experience */
  useEffect(() => {
    const initAtlas = () => {
      if (ready && authenticated && window.Atlas) {
        const {
          id,
          createdAt,
          ...rest
        } = user ?? {};
        posthog.identify(id, {
          createdAt: createdAt,
          ensName: ensName ?? undefined,
          ...rest
        });
        window.Atlas.call('identify', {
          userId: id
        });
      } else if (!authenticated) {
        posthog.reset();
      }
    };
    if (window.Atlas) {
      initAtlas();
    } else {
      window.addEventListener('AtlasLoaded', initAtlas);
      return () => window.removeEventListener('AtlasLoaded', initAtlas);
    }
  }, [authenticated, ensName, ready, user]);
  return children;
}