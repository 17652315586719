'use client';

import dynamic from 'next/dynamic';
import { Link } from 'next-view-transitions';
import { usePathname, useRouter } from 'next/navigation';
import { useAtom } from 'jotai';
import { usePrivy } from '@privy-io/react-auth';
import { cartAtom } from '~/app/stores/cart';
import Wordmark from '~/assets/wordmark.svg';
import { PrivyConnect } from '../auth/privy-connect';
import { CartButton } from '../cart/cart-button';
import { CartDialog } from '../cart/cart-dialog';
import { NavigationAndSearch } from './navigation-and-search';
import { NavLink } from './nav-link';
import { MoveUpRight } from 'lucide-react';
import { OUTPAINT_FARCASTER_CHANNEL } from '~/utils/constants';
const ThemeSwitcher = dynamic(() => import('./theme-switcher').then(mod => mod.ThemeSwitcher), {
  ssr: false
});
export function Navbar() {
  const pathname = usePathname();
  const router = useRouter();
  return <header className="relative" data-sentry-component="Navbar" data-sentry-source-file="navbar-temp.tsx">
      <nav className="container sticky top-0 z-50 my-4 flex flex-col gap-2 bg-transparent px-1 md:flex-row md:items-center md:justify-between md:gap-0 md:px-11 md:py-4">
        {/* First line on mobile: /, CCO, Wallets */}
        <div className="flex flex-col items-center justify-start md:flex-row">
          <Link href="/" className="-mb-[4px] mr-8 flex-shrink-0" data-sentry-element="Link" data-sentry-source-file="navbar-temp.tsx">
            <Wordmark className="h-[21px] w-auto text-black dark:text-white" data-sentry-element="Wordmark" data-sentry-source-file="navbar-temp.tsx" />
          </Link>
        </div>

        {/* Second line on mobile: Search */}
        <div className="flex flex-1 items-center justify-between">
          <div className="flex w-full items-center rounded-full bg-white bg-white/50 py-[1px] pl-1 pr-2 backdrop-blur-2xl md:w-auto">
            <div className="flex md:items-center">
              <NavLink href="/customer-service" data-sentry-element="NavLink" data-sentry-source-file="navbar-temp.tsx">Customer Service</NavLink>
              <NavLink openInNewTab href={OUTPAINT_FARCASTER_CHANNEL} data-sentry-element="NavLink" data-sentry-source-file="navbar-temp.tsx">
                <span>Farcaster</span>
                <MoveUpRight size={14} className="mb-1 ml-1 inline-block opacity-70" data-sentry-element="MoveUpRight" data-sentry-source-file="navbar-temp.tsx" />
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </header>;
}